import { Component, Vue, Watch, Prop } from "vue-property-decorator";

enum TextType {
  simple = "simple",
  cardNumber = "cardNumber",
}

@Component
export default class MaskedText extends Vue {
  @Prop({ type: Function })
  private readonly unmaskFunction?: (args: Record<string, unknown>) => string;
  @Prop({ type: Object })
  private readonly unmaskFunctionArgs?: Record<string, unknown>;

  @Prop({ type: String, default: "" })
  private readonly value!: string;
  @Prop({ type: String, default: TextType.simple })
  private readonly type!: TextType;
  @Prop({ type: Boolean, default: false })
  private readonly inset!: boolean;
  @Prop({ type: Boolean, default: true })
  private readonly showedProgress!: boolean;
  @Prop({ type: Number, default: 15 })
  private readonly showDuration!: number;
  @Prop({ type: Boolean, default: true })
  private readonly masked!: boolean;

  private countdownIntervalId = 0;
  private countdownSeconds = 0;
  private localMasked = true;
  private localValue = "";
  private loading = false;

  private get countdownPercent() {
    return Math.ceil((this.countdownSeconds * 100) / this.showDuration);
  }

  private get maskedValue() {
    if (!this.localMasked) {
      return this.localValue;
    }

    if (this.type === TextType.cardNumber) {
      return this.localValue.replace(/[^ ](?=.*.{4})/gi, "•");
    }

    return this.localValue.replace(/[^ ]/gi, "•");
  }

  @Watch("value", {
    immediate: true,
  })
  private onChangeValue() {
    this.localValue = this.value;
  }

  private async unmaskValue() {
    if (this.countdownIntervalId || !this.localMasked) {
      return this.maskedValue;
    }

    if (this.unmaskFunction && this.unmaskFunctionArgs) {
      this.loading = true;

      try {
        this.localValue = await this.unmaskFunction(this.unmaskFunctionArgs);

        this.loading = false;
      } catch {
        this.loading = false;
      }
    }

    this.localMasked = false;

    this.countdownSeconds = this.showDuration;
    this.countdownIntervalId = window.setInterval(() => {
      if (--this.countdownSeconds > 0) return;

      window.clearInterval(this.countdownIntervalId);
      this.countdownIntervalId = 0;
      this.maskValue();
    }, 1e3);

    return this.maskedValue;
  }

  private maskValue() {
    this.localMasked = true;
    this.localValue = this.value;

    this.countdownSeconds = 0;
    window.clearInterval(this.countdownIntervalId);
    this.countdownIntervalId = 0;
  }

  private mounted() {
    this.localMasked = this.masked;
  }

  private beforeDestroy() {
    window.clearInterval(this.countdownIntervalId);
  }
}
