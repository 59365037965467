var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'd-flex',
    'align-center',
    'masked-text',
    {
      'masked-text_inset': _vm.inset,
      'masked-text_outset': !_vm.inset,
    },
  ]},[_vm._t("default",null,{"maskedValue":_vm.maskedValue,"unmaskValue":_vm.unmaskValue,"maskValue":_vm.maskValue,"masked":_vm.localMasked,"countdownPercent":_vm.countdownPercent,"loading":_vm.loading}),(!_vm.inset)?[(_vm.showedProgress)?_c('div',{class:[
        'flex-shrink-0',
        'overflow-hidden',
        'masked-text__progress',
        {
          'masked-text__progress_hidden': !_vm.countdownPercent && !_vm.loading,
        },
      ]},[_c('v-progress-circular',{attrs:{"value":!_vm.loading ? _vm.countdownPercent : undefined,"indeterminate":_vm.loading,"color":"primary","size":"16","width":"2"}})],1):_vm._e(),_vm._t("append",null,{"maskedValue":_vm.maskedValue,"unmaskValue":_vm.unmaskValue,"maskValue":_vm.maskValue,"masked":_vm.localMasked,"countdownPercent":_vm.countdownPercent,"loading":_vm.loading})]:[(!_vm.countdownPercent && !_vm.loading)?_vm._t("append",null,{"maskedValue":_vm.maskedValue,"unmaskValue":_vm.unmaskValue,"maskValue":_vm.maskValue,"masked":_vm.localMasked,"countdownPercent":_vm.countdownPercent,"loading":_vm.loading}):(_vm.showedProgress)?_c('v-progress-circular',{attrs:{"value":!_vm.loading ? _vm.countdownPercent : undefined,"indeterminate":_vm.loading,"size":_vm.$vuetify.breakpoint.mobile ? 20 : 25,"width":_vm.$vuetify.breakpoint.mobile ? 1 : 2,"color":"primary"}},[_vm._t("append",null,{"maskedValue":_vm.maskedValue,"unmaskValue":_vm.unmaskValue,"maskValue":_vm.maskValue,"masked":_vm.localMasked,"countdownPercent":_vm.countdownPercent,"loading":_vm.loading})],2):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }